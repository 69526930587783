body,
html {
  padding: 0px;
  margin: 0px;
  font: normal 13px 'Roboto', Arial, sans-serif;
}

.config_header {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.draw {
  position: absolute;
  top: 75px;
  right: 110px;
  z-index: 1000;
}

#gcd-container {
  position: absolute;
  top: 7px;
  left: 40px;
  z-index: 1000;
}

.viewport {
  position: absolute;
  top: 75px;
  right: 60px;
  z-index: 1000;
}

.upload-calc-field {
  position: absolute;
  top: 75px;
  right: 10px;
  z-index: 1000;
}
.map {
  border: 1px solid #eeeeee;
  width: 100%;
  height: 100vh;
}

.icon {
  height: 16px;
  filter: invert(43%) sepia(100%) saturate(2614%) hue-rotate(195deg)
    brightness(101%) contrast(102%);
}

.custom_dialog {
  width: 550px;
  max-width: 550px !important;
}

.attribute_popup {
  width: 500px;
  max-width: 500px !important;
}

.info_grid {
  font: 11px 'Roboto', Arial, sans-serif;
  overflow-y: hidden;
  height: 155px;
}
.info_grid .rdg-header-row * {
  font-weight: bold;
  background-color: #eeeeee;
}

.info_grid_no_records {
  font-weight: bold;
  font-size: x-large;
  text-align: center;
}

.info-layer-selection {
  margin-bottom: 7px;
}
.rotate-45 {
  transform: rotate(45deg);
}

.big-bold {
  font-size: 1.5em;
  font-weight: bold;
}

.blue {
  color: #1e88e5;
}

.grey {
  color: #9e9e9e;
}

.text-fit {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.layers-panel {
  width: 200px;
  background-color: #f8f9fa;
  opacity: 0.9;
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
  border-radius: 25px;
  padding-right: 2px;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.layers-panel-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;
  background-color: #c2c0c0;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

button#colormap-dropdown {
  width: 146px;
  border: 1px solid #c2c0c0;
}